/* eslint-disable */
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Image,
	Input,
	InputGroup,
	Text,
	useColorModeValue,
	Menu,
	MenuItem,
	MenuButton,
	MenuList,
	Heading,
	Stack,
	VStack,
	useColorMode,
} from '@chakra-ui/react'
// Custom components
import { GoChevronDown } from 'react-icons/go'

import { useTranslation } from 'react-i18next'
import DefaultAuth from 'layouts/auth/Default'
// Assets
import AuthApi from '../../../api/auth'
import { setLanguage } from 'i18n'
import FadeTransition from 'components/pageTransition/FadeTransiton'
import { LogEvent, LogEventParams } from '../../../analytics/analytics'
import { openWhatsAppSupport } from '../../../utils/Support'
import headSetIcon from '../../../assets/img/icons/headset_mic.png'
import { getMobileOperatingSystem, Mobile } from '../../../utils/DeviceUtils'
import {useAuth} from "../../../auth-context/auth.context";


function SignIn() {
	const [phoneNumber, setPhoneNumber] = useState('') // <-- Default values HERE
	const [employeeId, setEmployeeId] = useState('') // <-- Default values HERE
	const [error, setError] = useState(undefined)
	const { colorMode, toggleColorMode } = useColorMode()
	const history = useHistory()
	const { user } = useAuth();

	useEffect(() => {
		LogEvent('auth_view')
		if (colorMode === 'light') {
			toggleColorMode()
		}
		if (user) {
			history.push('/rtl-default')
		}
	}, [])

	const [show, setShow] = React.useState(false)
	const onPhoneNumberInput = (e) => {
		if (e.target.value.length > e.target.maxLength)
			e.target.value = e.target.value.slice(0, e.target.maxLength)
	}
	const handleClick = () => setShow(!show)
	const login = async (event) => {
		if (event) {
			event.preventDefault()
		}
		if (phoneNumber === '') {
			return setError(t('login_1_textfield_missing_error_text'))
		}
		if (employeeId === '') {
			return setError(t('login_1_textfield2_missing_error_text'))
		}
		// setButtonText("מתחבר");

		try {
			let response = await AuthApi.Auth(phoneNumber, employeeId)
			if (response.data && response.data.success === false) {
				//setButtonText("Sign in");
				return setError(response.data.msg)
			}
			return goToOtp(response)
		} catch (err) {
			console.log(err)

			if (err && err.response) {
				switch (err.response.status) {
					case 401:
						return setError(t('login_error_no_user'))
					case 400:
						return setError('אנא הכנס מספר טלפון תקין')
					case 402:
					{
						const code = err.response.data.code;
						// underage
						if(code === 111) {
							return setError(t('login_failed_underage'))
						} else if(code === 110) {
							// UserNoDetailsError
							return setError(t('login_failed_no_details'))
						} else if(code === 112) {
							// UserNotExists
							return setError(t('login_error_no_user'))
						}
						break;
					}
					// code block
				}
			} else if (err.message) {
				return setError(err.message)
			}
			return setError('שגיאה')
		}
	}

	const goToOtp = async (response) => {
		// let user = { ...response.data.user };
		// user.token = response.data.token;
		// user = JSON.stringify(user);
		// setUser(user);
		// localStorage.setItem("user", user);
		// return history.push("/dashboards");
		// return history.push("/auth/otp");
		AuthApi.sessionToken = response.data.response.sessionToken
		localStorage.setItem(
			'tempSessionToken',
			response.data.response.sessionToken,
		)
		return history.push('/auth/otp')
	}
	const mobileOs = getMobileOperatingSystem()

	const { t } = useTranslation()
	return (
		<FadeTransition>
			<DefaultAuth>
				<Stack
					minH={
						mobileOs === Mobile.Android
							? 'calc(100vh - 175px)'
							: 'calc(100vh - 230px)'
					}
				>
					<Stack mt="2">
						<VStack>
							<Heading textAlign="center" fontSize="34px" fontWeight="bold">
								{t('welcome_to_payro_title')}
							</Heading>
							<Text
								textAlign="center"
								maxW="32ch"
								mt="22px !important"
								fontSize="18px"
								lineHeight="1.4"
							>
								{t('login_1_message')}
							</Text>
							<h4
								style={{
									fontSize: '.9em',
									color: 'red',
									textAlign: 'center',
									fontWeight: 400,
									transition: '.2s all',
								}}
							>
								{error}
							</h4>
						</VStack>
					</Stack>
					<Stack spacing={'7'} style={{marginTop:"50px"}}>
						<Input
							borderRadius="4px"
							px="3"
							height="50px"
							textAlign="center"
							isRequired={true}
							type="number"
							maxLength="10"
							placeholder={t('login_1_textfield_hint')}
							onInput={onPhoneNumberInput}
							variant="auth"
							_focus={{ borderColor: 'newBrand.500' }}
							fontSize="18px"
							borderColor="secondaryGray.600"
							onChange={(event) => {
								setPhoneNumber(event.target.value)
								setError(undefined)
							}}
						/>
						<Input
							isRequired={true}
							borderRadius="4px"
							_focus={{ borderColor: 'newBrand.500' }}
							px="3"
							height="50px"
							textAlign="center"
							type="number"
							size="lg"
							placeholder={t('login_1_textfield2_hint')}
							variant="auth"
							borderColor={'secondaryGray.600'}
							onChange={(event) => {
								setEmployeeId(event.target.value)
								setError(undefined)
							}}
						/>

						<Button w="100%" onClick={login} variant="newBrand">
							{t('login_1_button_text')}
						</Button>
						<Text pt="1" fontSize="18px" textAlign="center">
							{t('employer_not_offering_payro')}
							<Box
								as="span"
								fontWeight="bold"
								textDecor="underline"
								mr="2"
								onClick={openWhatsAppSupport}
							>
								{t('connect_us_to_your_employer')}
							</Box>
						</Text>
					</Stack>
					<Stack align={'center'} style={{marginTop:"30px"}}>
						<Button
							variant="greenOutlineBrand"
							rightIcon={<Image src={headSetIcon} />}
							onClick={openWhatsAppSupport}
						>
							{t('customer_support_button')}
						</Button>

						{/*<VStack pt="4">*/}
						{/*	<Menu display="flex" alignItems="center" justifyContent="center">*/}
						{/*		<MenuButton as={Button} rightIcon={<GoChevronDown />}>*/}
						{/*			{t('language_native')}*/}
						{/*		</MenuButton>*/}
						{/*		<MenuList>*/}
						{/*			<MenuItem value="heb" onClick={changeLanguage}>*/}
						{/*				עברית*/}
						{/*			</MenuItem>*/}
						{/*			<MenuItem value="en" onClick={changeLanguage}>*/}
						{/*				English*/}
						{/*			</MenuItem>*/}
						{/*			<MenuItem value="ar" onClick={changeLanguage}>*/}
						{/*				عربى*/}
						{/*			</MenuItem>*/}
						{/*		</MenuList>*/}
						{/*	</Menu>*/}
						{/*</VStack>*/}
					</Stack>
				</Stack>
			</DefaultAuth>
		</FadeTransition>
	)
}

export default SignIn
