import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

const WelcomeInfoSection = ({ item }) => {
  return (
    <Box dir="rtl" style={{backgroundColor: 'transparent' }}>
      <Stack mt="2">
        <Image boxSize="200px" mx="auto" src={item.image} alt="welcome" />
        <Heading
          mt="4 !important"
          as="h2"
          fontSize="24px"
          fontWeight="600" pt="4">
          {item.heading}
        </Heading>
        <Text pt="4" fontSize="18px" style={{opacity: "0.8", lineHeight: "160%"}}>
          {item.text}
        </Text>
      </Stack>
    </Box>
  );
};

export default WelcomeInfoSection;
