import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import welcomeBackground from "../../assets/img/layout/welcome_bg.png"; // Corrected path

// Chakra imports
import {
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import {getMobileOperatingSystem, Mobile} from "../../utils/DeviceUtils";


export default function Auth() {
  // states and functions

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/auth/full-screen-maps";
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
            <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
            />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";

  const mobileOs = getMobileOperatingSystem();

  return (
      <Box
          minHeight={mobileOs === Mobile.Android ? "calc(100vh - 50px)" : "calc(100vh - 50px)"}
          width="100vw"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundImage={`url(${welcomeBackground})`}
          backgroundSize="cover"
          backgroundPosition="center"
      >
        <Box dir="rtl" width="100%">
          {getRoute() ? (
              <Box>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/auth" to="/auth/sign-in/default" />
                </Switch>
              </Box>
          ) : null}
        </Box>
      </Box>

  );
}
