import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Icon,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React, {useEffect, useMemo, useState} from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
  
  // Custom components
  import Card from "components/card/Card";
  import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
  
import { useTranslation } from 'react-i18next';
  
  import {
    TagStatus,
    TagAmount,
    TagDate, withdrawalsHistoryColumns, withdrawalsHistoryData
  } from "views/admin/default/variables/withdrawalsHistory";
import {prettifyDate} from "../../../../utils/dateUtils";
import {ApplicationStatus} from "../../../../api/transfers";

  
  export default function WithdrawalsHistoryTable(props) {
    const { details } = props;

      // alert(details.transfersHistory)
    const columns = useMemo(() => withdrawalsHistoryColumns, [withdrawalsHistoryColumns]);
      const [data, setData] = useState([]);//useMemo(() => details.transfersHistory, [details.transfersHistory]);

      useEffect(() => {
          if(details && details.transfersHistory) {
              setData(details.transfersHistory);
          }
      }, [details])

      const tableInstance = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
    } = tableInstance;
    initialState.pageSize = 50;
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    
    
  const { t } = useTranslation();
    return (
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' align='center'>
          <Text
            color={textColor}
            fontSize='18px'
            fontWeight='700'
            lineHeight='100%'>
            {t('main_card_4_title')}
          </Text>
        </Flex>
          { data != null && data.length > 0 &&
              <Table mt={'20px'} {...getTableProps()}  color='gray.500' mb='24px'>
                  <Thead>
                      {headerGroups.map((headerGroup, index) => (
                          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                              {headerGroup.headers.map((column, index) => (
                                  <Th
                                      {...column.getHeaderProps()}
                                      pe='10px'
                                      key={index}
                                      borderColor={borderColor}>
                                      <Flex
                                          justify='space-between'
                                          align='center'
                                          fontSize={{ sm: "14px", lg: "12px" }}
                                          color='gray.400'>
                                          {t(column.render("Header"))}
                                      </Flex>
                                  </Th>
                              ))}
                          </Tr>
                      ))}
                  </Thead>
                  <Tbody {...getTableBodyProps()}>
                      {page.map((row, index) => {
                          prepareRow(row);
                          return (
                              <Tr {...row.getRowProps()} key={index}>
                                  {row.cells.map((cell, index) => {
                                      let data = "";
                                      let cellWidth = cell.column.width;
                                      if (cell.column.tag === TagStatus) {
                                          data = (
                                              <Flex align='center'>
                                                  <Icon
                                                      w='0px'
                                                      h='0px'
                                                      color={
                                                          cell.value >= ApplicationStatus.PendingTransfer ? "green.500"
                                                              : cell.value < ApplicationStatus.PendingTransfer ? "red.500" : null
                                                      }
                                                      as={
                                                          cell.value >= ApplicationStatus.PendingTransfer ? MdCheckCircle
                                                              : cell.value < ApplicationStatus.PendingTransfer ? MdOutlineError: null
                                                      }
                                                  />
                                                  <Text
                                                      me='0px'
                                                      marginStart='8px'
                                                      color={textColor}
                                                      fontSize='13px'
                                                      fontWeight='600'>
                                                      {
                                                          cell.value === ApplicationStatus.Transferred ? t('status_transferred')
                                                              : cell.value === ApplicationStatus.PendingTransfer ? t('status_transferred')
                                                                  : null}
                                                  </Text>
                                              </Flex>
                                          );
                                      } else if (cell.column.tag === TagAmount) {
                                          data = (
                                              <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                  ₪{cell.value}
                                              </Text>
                                          );
                                      } else if (cell.column.tag === TagDate) {
                                          data = (
                                              <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                  {prettifyDate(cell.value)}
                                              </Text>
                                          );
                                      }
                                      return (
                                          <Td
                                              {...cell.getCellProps()}
                                              key={index}
                                              fontSize={{ sm: "14px" }}
                                              maxH='30px !important'
                                              py='8px'
                                              borderColor='transparent'>
                                              {data}
                                          </Td>
                                      );
                                  })}
                              </Tr>
                          );
                      })}
                  </Tbody>
              </Table> }
          { data == null || data.length === 0 &&
              <Flex padding={'20px'} justifyContent={'center'} direction={'row'} align={'center'} alignItems={'center'}>
                  <Text fontSize={'18px'} textAlign={'center'}>
                      {t('no_data')}
                  </Text>
              </Flex>
          }
      </Card>
    );
  }
  