import axios from "./index";
import MockApi from "./mockApi";
import {API} from "./env";

export const ApplicationStatus = {
    Canceled: 0,
    Declined: 100,
    PendingTransfer: 300,
    PendingApproval: 400,
    Transferred: 500,
    TransferReceipt: 600,
    Completed: 700
}

class TransfersApi {

    /**
     * Also serves a first API once user enters first screen
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getInfo = () => {
        if(MockApi.IsActive) {
            return MockApi.GetApplicationsInfo();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        return axios.get(`${API}/applications`, config);
    };

    static prepApplication = (amount) => {
        if(MockApi.IsActive) {
            return MockApi.PrepApplication();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const data = JSON.stringify({
            "amount": amount
        });

        return axios.post(`${API}/applications/prepare`, data, config);
    };

    static createNewTransfer = (amount) => {
        if(MockApi.IsActive) {
            return MockApi.CreateNewTransfer();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const data = JSON.stringify({
            "amount": amount,
            "transferType": 1
        });

        return axios.post(`${API}/applications`, data, config);
    };

    static approve = (requestId, otpCode) => {
        if(MockApi.IsActive) {
            return MockApi.ApproveTransfer();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const data = JSON.stringify({
            "request": requestId,
            "otpCode": otpCode
        });

        return axios.post(`${API}/applications/otp`, data, config);
    };
}

export default TransfersApi;