import {
    Flex,
    useColorModeValue,
    Text, Icon,
    Button,
    useDisclosure
} from "@chakra-ui/react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
} from '@chakra-ui/react'

// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React, {useEffect, useState} from "react";
import {HiQuestionMarkCircle} from "react-icons/hi";
import {HiHeart } from "react-icons/hi";
import { useTranslation } from 'react-i18next';
import {getCurrentMonthTranslationKey} from "../../utils/dateUtils";
import {LogEvent} from "../../analytics/analytics";
import ReactSession from "../../utils/ReactSession";

export default function EarningCard(props) {
    
    const { t } = useTranslation();
    const { endContent, details } = props;
    const textColorSecondary = "secondaryGray.900";
    const title = t('main_card_1_title');
    const earnedWage = details.earnedWage ? details.earnedWage : 0;
    const totalWorkingHours = details.totalWorkingHours ? details.totalWorkingHours : 0;
    const value =  earnedWage + " ₪";
    const subValue =  totalWorkingHours +  t('main_card_1_subtitle');
    const subtitleFontSize = '18px'
    const margin = '15px'
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ blurText, setBlurText] = useState(true);
    const key = "earning_tab_info1"

    useEffect(() => {
        const shouldBlur = ReactSession.get(key) !== "1"
        setBlurText(shouldBlur)
    }, []);

    const onInformationClicked = () => {
        LogEvent("earned_i_click")
        onOpen()
    }

    const handleAlertApprove = () => {
        onClose()
        ReactSession.set(key, "1");
        setBlurText(false)
    }

    const handleCardClick = () => {
        onOpen()
    }

    const blurStyles = blurText === true ? {
        'transition': 'filter 400ms',
        '-webkit-filter': 'blur(15px)',
        'filter': 'blur(15px)'
    } : {} ;

    return (
        <Card>
            <Flex
                flexDirection={'column'}
                alignItems={'right'}
                my='auto'
                h='100%'>
                <Flex onClick={handleCardClick}
                      flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}
                      >
                    <Text
                        lineHeight='100%'
                        color={textColorSecondary}
                        fontSize={subtitleFontSize}>
                        {title}
                    </Text>
                    
                    <Icon
                        as={HiQuestionMarkCircle}
                        w='30px'
                        h='30px'
                        onClick={onInformationClicked}
                        color={'brand.500'} />
                </Flex>

                { blurText && <Button style={{position: "absolute", marginRight: "auto", marginLeft:"auto", marginTop:"80px", left: "40px", right: "40px"}}
                                      variant={"brand"}  onClick={handleCardClick}
                                      textColor={'white'}>{t('click_here_button')}</Button>
                }

                <Text
                    mt={margin}
                    lineHeight='100%'
                    color={textColorSecondary}
                    fontSize={'40px'}
                    fontWeight={'bold'}
                    blur={'10px'}
                    style={blurStyles}
                    onClick={handleCardClick}>
                    {value}
                </Text>
                <Text
                    lineHeight='100%'
                    color={textColorSecondary}
                    fontWeight={'semibold'}
                    fontSize={'12px'}
                    style={blurStyles}
                    onClick={handleCardClick}>
                    {t('estimate_only')}
                </Text>
                <Text
                    mt={margin}
                    lineHeight='100%'
                    color={textColorSecondary}
                    fontSize={subtitleFontSize}
                    fontWeight='400'
                    style={blurStyles}
                    onClick={handleCardClick}>
                    {subValue}
                </Text>
                <Text
                    mt={margin}
                    lineHeight='100%'
                    color={textColorSecondary}
                    fontSize={subtitleFontSize}
                    fontWeight='400'
                    style={blurStyles}
                    onClick={handleCardClick}>
                    {t(getCurrentMonthTranslationKey()) + ' ' + new Date().getFullYear()}
                </Text>

                <Flex ms='auto'>

                    <Modal
                        isOpen={isOpen}
                        onClose={onClose}
                        isCentered>
                        <ModalOverlay />
                        <ModalContent maxW="350px">
                            <ModalBody>
                                <Flex marginTop={'10px'}
                                      flexDirection={'row'}
                                      justifyContent={'center'}
                                      alignItems={'center'}
                                align={'center'} >
                                    <Text fontWeight='bold' fontSize={'35px'}>
                                        {t('earnings_prompt_title')}
                                    </Text>
                                    <Icon
                                        margin={'10px'}
                                        as={HiHeart}
                                        w='40px'
                                        h='40px'
                                        onClick={onInformationClicked}
                                        color={'red'} />
                                </Flex>

                                <Flex marginTop={'10px'} flexDirection={'column'}>
                                        <Text fontSize={'20px'}>
                                            {t('earnings_prompt_message_1_1')} <strong>{t('earnings_prompt_message_1_2')}</strong> {t('earnings_prompt_message_1_3')}
                                        </Text>
                                    <Text margin={'10px'} fontSize={'20px'}>{t('earnings_prompt_message_2')}</Text>
                                    <Text margin={'10px'} fontSize={'20px'}>{t('earnings_prompt_message_3')}</Text>
                                </Flex>
                                <Text margin={'10px'} fontSize={'18px'} fontWeight={'bold'}>{t('earnings_prompt_message_4')}</Text>
                            </ModalBody>

                            <ModalFooter>
                                <Button fontSize={'22px'} variant={"brand"} onClick={handleAlertApprove}>{t('earnings_prompt_confirm_button')}</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>


                </Flex>
            </Flex>
        </Card>
    );
}
