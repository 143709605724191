import React, { useEffect } from "react";
import PropTypes from "prop-types";

export function getInitialState() {
  const user = localStorage.getItem('user')
  return user ? user : null
}

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {

  let [user, setUser] = React.useState(getInitialState);

  useEffect(() => {

    if(user == null) {
      localStorage.removeItem('user');
    } else if(!getInitialState()) {
      localStorage.setItem('user', JSON.stringify(user))
    }

  }, [user])

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};


export const useAuth = () => React.useContext(AuthContext);
