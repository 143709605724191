
export default class MockApi {
    static IsActive = false;

    static GetApplicationsInfo = () => {
        let response = {
            data: {
                totalWorkingHours: 44,
                earnedWage: 1281,
                totalTransferred: 100,
                availableToTransfer: 540,
                transfersHistory: [
                    {
                        id:6,
                        status: 500,
                        amount:100,
                        createdDate: "2023-01-07T03:24:00"
                    }
                ],
                timeReporting: [
                    {
                        workingHours:7,
                        startTime: "2023-01-01T03:24:00",
                        endTime: "2023-01-01T10:24:00"
                    },
                    {
                        workingHours:8.5,
                        startTime: "2023-01-02T03:24:00",
                        endTime: "2023-01-02T11:54:00"
                    },
                    {
                        workingHours:6,
                        startTime: "2023-01-04T03:24:00",
                        endTime: "2023-01-04T10:24:00"
                    },
                    {
                        workingHours:9.5,
                        startTime: "2023-01-05T03:24:00",
                        endTime: "2023-01-05T10:24:00"
                    },
                    {
                        workingHours:6.5,
                        startTime: "2023-01-06T03:24:00",
                        endTime: "2023-01-05T10:24:00"
                    }
                    ,
                    {
                        workingHours:6.5,
                        startTime: "2023-01-07T03:24:00",
                        endTime: "2023-01-05T10:24:00"
                    }
                ]
            }
        }
        return Promise.resolve(response)
    }

    static Auth = () => {
        let response = {
            data: {
                response: {
                    code: 1,
                    sessionToken: "423212a30e584786ad9f9ccc3e6d0ce3b5e2023c46b674b3725581334b69bfd2"
                }
            },
            success: false
        }
        return Promise.resolve(response)
    };

    static Login = () => {
        let response = {
            data: {
                accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uVG9rZW4iOiI0MGUyNjMwYWI2Y2RiNmNkZmExNmU1M2NjZDQ3MzQ1ZWY2ZTZhNjMyMTVlYzRmZWU2ZTNmMTVhN2E0NjAwMDA4IiwiaWF0IjoxNjY3MjQ2MDIwLCJleHAiOjE2NjczMzI0MjB9.dnGP1W1SGe_QH7DCs7DgyiGq4eUEC5JiTZ_iQChDkBU"
            }
        }
        return Promise.resolve(response)
    }

    static GetUserInfo = () => {
        let response = {
            data: {
                id: 13,
                type: 2,
                phoneNumber: "0548868955",
                firstName: "אחמד",
                lastName: "טאה",
                title: "טבח",
                department: "",
                companyName: "קפה גרג גרנד קניון",
                companyId: 6,
                approveTc: false
            }
        }
        return Promise.resolve(response)
    }

    static PrepApplication = () => {
        let response = {
            data: {
                errors: [{
                    code: 202,
                }],
                transferFee: "7.99"
            }
        }
        return Promise.resolve(response)
    }

    static UpdateBankAccountInfo = () => {
        let response = {
            data: {

            }
        }
        return Promise.resolve(response)
    }

    static CreateNewTransfer = () => {
        let response = {
            data: {
                requestId: "dsadsadsadsadsad"
            }
        }
        return Promise.resolve(response)
    }

    static ApproveTransfer = () => {
        let response = {
            data: {
                response: true
            }
        }
        return Promise.resolve(response)
    }

}