// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton
} from '@chakra-ui/react'

// Custom components
import Banner from "views/admin/profile/components/Banner";

import React, {useEffect} from "react";
import ReactSession from "../../../utils/ReactSession";
import {handleLogout} from "../../../utils/Logout";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../auth-context/auth.context";
import {GoChevronDown} from "react-icons/go";
import i18n, {setLanguage} from "../../../i18n";
import {useTranslation} from "react-i18next";
import FadeTransition from "../../../components/pageTransition/FadeTransiton";
import Card from "../../../components/card/Card";
import {useDisclosure} from "@chakra-ui/react";
import FasterFadeTransition from "../../../components/pageTransition/FasterFadeTransition";
import {LogEvent, LogEventParams} from "../../../analytics/analytics";

export default function Overview() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const userInfo = ReactSession.get("user_info");
  const name = userInfo.firstName + " " + userInfo.lastName;
  const history = useHistory();
  const { setUser } = useAuth();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  useEffect(() => {
    LogEvent("profile_view")
  }, []);

  let jobDesc = "";
  if (userInfo.title && userInfo.department) {
    jobDesc = userInfo.title + ", " + userInfo.department;
  } else if (userInfo.title) {
    jobDesc = userInfo.title;
  } else if (userInfo.department) {
    jobDesc = userInfo.department;
  }

  const changeLanguage = (ln) => {
    LogEventParams("profile_set_language", { lang: ln.target.value } )
    setLanguage(ln.target.value)
  };

  const handleLogoutClick = () => {
    LogEvent("profile_logout")
    handleLogout(setUser, history)
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const customerServiceClick = (e) => {
    LogEvent("profile_customer_support_click")
    openInNewTab("https://wa.me/972559995677");
  };

  return (
    <FasterFadeTransition>
      <Box pt={{ base: "50px", md: "80px", xl: "80px" }}>
        {/* Main Fields */}
        <Grid
          gap={{ base: "20px", xl: "20px" }}
        >
          <Banner gridArea="1 / 1 / 2 / 2" name={name} job={jobDesc} />
          <Card>
              <Button
                  style={{alignSelf: 'flex-start'}}
                  color={textColorPrimary}
                  fontSize='20px'
                  fontWeight={'bold'}
                  mt='10px'
                  onClick={customerServiceClick}>
                {t("sidebar_customer_service")}
              </Button>
            <Button
                style={{alignSelf: 'flex-start'}}
                color={textColorPrimary}
                fontSize='20px'
                fontWeight={'bold'}
                onClick={onOpen}
                mt='20px'>
              {t("sidebar_logout")}
            </Button>
          </Card>
          <Flex
                alignItems="center"
                justifyContent={'center'}
                direction={'row'}>
            <Menu>
              <MenuButton as={Button} rightIcon={<GoChevronDown />} backgroundColor={'transparent'}>
                {t("language_native")}
              </MenuButton>
              <MenuList>
                <MenuItem value="heb"  onClick={changeLanguage}>
                  עברית
                </MenuItem>
                {/*<MenuItem value="en" onClick={changeLanguage}>*/}
                {/*  English*/}
                {/*</MenuItem>*/}
                <MenuItem value="ar" onClick={changeLanguage}>
                  عربى
                </MenuItem>
              </MenuList>
            </Menu>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogHeader>{t("sidebar_logout")}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  {t("logout_message")}
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    {t("no")}
                  </Button>
                  <Button colorScheme='red' ml={3} onClick={handleLogoutClick}>
                    {t("sidebar_logout")}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </Flex>
        </Grid>
      </Box>
    </FasterFadeTransition>
  );
}
