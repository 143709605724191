import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../auth-context/auth.context";
import { NavigateTo, Page } from "../nav/navigator";
import ReactSession from "../utils/ReactSession";

export const ProtectedRoute = ({ ...rest }) => {
  let { user } = useAuth();
  if (!user) {
    const welcomeWizard = ReactSession.get("welcome_wizard_view");
    if(welcomeWizard === undefined || welcomeWizard == null) {
      NavigateTo(Page.Welcome);
    } else {
      NavigateTo(Page.SignIn);
    }
    return null;
  } else if (user) {
    try {
      let userJson;
      if (typeof user === "string") {
        userJson = JSON.parse(user);
      } else if (typeof user === "object") {
        userJson = user;
      }
      if (!userJson || !userJson.token || userJson.token === "") {
        NavigateTo(Page.Welcome);
        return null;
      }
    } catch (e) {
      NavigateTo(Page.SignIn);
      return null;
    }
  }

  return <Route {...rest} />;
};
