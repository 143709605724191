import React, {useEffect, useMemo} from "react";
import ControlledSlider from "./ControlledSlider";
import Input from "./Input";
import {Flex, Text, Box} from "@chakra-ui/react";

const min = 50;
const step = 5;
const value = 50;

class CustomInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxAmount: this.props.max,
            sum: value,
            sliderSum: value,
            step: step,
            ...props
        };
    }

    handleChange = (sliderValue) => {
        this.state.onChange(sliderValue)
    };

    componentDidMount() {
        //alert('CustomInput : componentDidMount ' + JSON.stringify(this.state))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value || prevProps.max !== this.props.max) {
            let existingState = this.state;
            existingState.sum = this.props.value;
            existingState.sliderSum = this.props.value;
            existingState.maxAmount = this.props.max;
            this.setState(existingState);
            this.handleChange(existingState.sliderSum)
        }
     }

    onInputChange = (value) => {
        if (value) {
            const sum = parseInt(value, 10);
            this.setState({
                sum: sum
            });

            // Update slider with new value only if it’s within the sliders domain
            if (sum >= min && sum <= this.state.maxAmount) {
                this.setState({ sliderSum: sum });
            }
        } else {
            // Empty string is preferred when dealing with controlled components
            this.setState({ sum: "" });
        }
    };

    onSlideStart = (value) => {
        // Set the step value sent to the slider when the user starts dragging
        this.setState({ step: step });
    };

    onSliderChange = (value) => {
        const sum = parseInt(value, 10);

        // When the slider is changed, set both input and slider values to reflect new value
        this.setState({
            sum,
            sliderSum: sum
        });
        this.handleChange(sum)
    };


render() {
        const inputStyle = {
                border: 'none',
                borderColor: "#c0c0c070",
                borderWidth: "1px",
                backgroundColor: "white",
                fontWeight: "bold"
            };
        return (
            <div>
                <Flex flexDirection='row' justifyContent='center' alignItems={'center'}>
                    <Input
                        prefix={"hello"}
                        type='number'
                        style={inputStyle}
                        name={"sliderinput"}
                        id={"sliderinput"}
                        value={this.state.sum}
                        onChange={(e) => this.onInputChange(e.target.value)}
                        onFocus={() => this.setState({ step: 1 })} // When the input is focused, set step value to 1
                    />
                    <Text fontSize={'40px'}>₪</Text>
                </Flex>

                <ControlledSlider

                    min={min}
                    max={this.state.maxAmount}
                    step={this.state.step}
                    defaultValue={this.state.sliderSum}
                    onUpdate={(value) => this.onSliderChange(value)}
                    onSlideStart={(value) => this.onSlideStart(value)}
                />
                <Flex direction='row' justify='space-between' mt={'20px'}>
                    <Text fontSize={'18px'} minW={'60px'}>₪{this.state.maxAmount}</Text>
                    <Text fontSize={'18px'} minW={'45px'}>₪50</Text>
                </Flex>
            </div>
        );
    }
}

export default CustomInput;
