import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./translations/english.json";
import translationHB from "./translations/hebrew.json";
import translationAR from "./translations/arabic.json";
import ReactSession from "./utils/ReactSession";

const resources = {
    en:{
        translation: translationEN
    },
    heb:{
        translation: translationHB
    },
    ar: {
        translation: translationAR
    }
};

const defaultLanguage = "heb"

const getLanguage = () => {
    // to prevent bug that React Session is not initialized
    ReactSession.setStoreType("localStorage");
    const lang = ReactSession.get("selected_language")
    if(lang) {
        return lang;
    } else {
        return defaultLanguage;
    }
}

export const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
    ReactSession.set("selected_language", lang)
}

i18n.use(initReactI18next)
    .init({
        resources,
        lng: getLanguage(),
        keySeparator:false,

        interpolation:{
            escapeValue:false
        }
    });

export default i18n;