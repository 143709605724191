export class TransfersResponse{
    constructor(totalWorkingHours,
                earnedWage,
                totalTransferred,
                availableToTransfer,
                transfersHistory,
                timeReporting) {
        this.totalWorkingHours = totalWorkingHours;
        this.earnedWage = earnedWage;
        this.totalTransferred = totalTransferred;
        this.availableToTransfer = availableToTransfer;
        this.transfersHistory = transfersHistory;
        this.timeReporting = timeReporting;
    }

    static from(json){
        let object = new TransfersResponse();
        object.totalWorkingHours = Math.floor(json["totalWorkingHours"])
        object.earnedWage = Math.floor(json["earnedWage"])
        object.totalTransferred = Math.floor(json["totalTransferred"])
        object.availableToTransfer = Math.floor(json["availableToTransfer"])
        object.transfersHistory = json["transfersHistory"]
        object.timeReporting = json["timeReporting"]
        return object
    }
}
