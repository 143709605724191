import { mode, whiten } from "@chakra-ui/theme-tools";
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: "16px",
        boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
        transition: ".25s all ease",
        boxSizing: "border-box",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          boxShadow: "none",
        },
      },
      variants: {
        outline: () => ({
          borderRadius: "16px",
        }),
        brand: (props) => ({
          bg: mode("brand.500", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.500", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.500", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.600", "brand.400")(props),
          },
        }),
        newBrand: (props) => ({
          bg: mode("#18e482", "#18e482")(props),
          py: "28px",
          px: "1px",
          fontWeight: "medium",
          fontFamily: "Noto Sans Hebrew",
          fontSize: "18px",
          border: '0px solid #000000',
          borderRadius: "100px",
          color: "#12653D",
          _focus: {
            bg: mode("#18e482", "#18e482")(props),
          },
          _active: {
            bg: mode("#18e482", "#18e482")(props),
          },
          _hover: {
            bg: mode("#18e482", "#18e482")(props),
          },
        }),
        newOultineBrand: (props) => ({
          py: "28px",
          px: "1px",
          fontWeight: "medium",
          bg: mode("transparent", "transparent")(props),
          fontSize: "18px",
          borderRadius: "100px",
          border: "1px solid #12653D",
          textColor: "#12653D",
          _focus: {
            // bg: mode("newBrand.500", "newBrand.400")(props),
          },
          _active: {
            // bg: mode("brand.50", "brand.50")(props),
          },
          _hover: {
            // bg: mode("newBrand.600", "newBrand.400")(props),
          },
        }),
        greenOutlineBrand: (props) => ({
          py: "20px",
          px: "1px",
          width: "150px",
          fontWeight: "medium",
          bg: "#01C976",
          border: "1px solid",
          borderColor: mode("#01C976", "#01C976")(props),
          fontSize: "14px",
          borderRadius: "50px",
          color: mode("#01C976", "white")(props),
          _focus: {
            // bg: mode("newBrand.500", "newBrand.400")(props),
          },
          _active: {
            // bg: mode("brand.50", "brand.50")(props),
          },
          _hover: {
            // bg: mode("newBrand.600", "newBrand.400")(props),
          },
        }),
        darkBrand: (props) => ({
          bg: mode("brand.900", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.800", "brand.400")(props),
          },
        }),
        lightBrand: (props) => ({
          bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        light: (props) => ({
          bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          color: mode("secondaryGray.900", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        action: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("secondaryGray.300", "brand.400")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "brand.400")(props),
          },
          _active: { bg: mode("secondaryGray.300", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.200", "brand.400")(props),
          },
        }),
        setup: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("transparent", "brand.400")(props),
          border: mode("1px solid", "0px solid")(props),
          borderColor: mode("secondaryGray.400", "transparent")(props),
          color: mode("secondaryGray.900", "white")(props),
          _focus: {
            bg: mode("transparent", "brand.400")(props),
          },
          _active: { bg: mode("transparent", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.100", "brand.400")(props),
          },
        }),
      },
    },
  },
};
