//import * as firebase from 'firebase'
import 'firebase/analytics'
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import MockApi from "../api/mockApi";

const config = {
    apiKey: "AIzaSyD6kk4FE9ao2o2YY3EqdryZ4lTZv4Xhstw",
    authDomain: "payro-cloud.firebaseapp.com",
    projectId: "payro-cloud",
    storageBucket: "payro-cloud.appspot.com",
    messagingSenderId: "852849653265",
    appId: "1:852849653265:web:1b5a77edc10233626263b0",
    measurementId: "G-2PXM54T6FT"
};

export const defaultAnalytics = getAnalytics(initializeApp(config))

export const shouldLog = () => {
    return !MockApi.IsActive && window.location.href.includes("mobile.payro.io");
}

export const setSessionToken = (session) => {
    try {
        setUserProperties(defaultAnalytics, { session_token: session });
    } catch (e) {}
}

export const LogEvent = (eventName) => {
    if(shouldLog()) {
        logEvent(defaultAnalytics, eventName);
    } else {
        console.log("LogEvent " + eventName)
    }
}

export const LogEventParams = (eventName, params) => {
    if(shouldLog()) {
        logEvent(defaultAnalytics, eventName, params);
    } else {
        console.log("LogEvent " + eventName + " " + JSON.stringify(params))
    }
}