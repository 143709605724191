import {
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const BottomNavbar = ({ routes }) => {
  const { t } = useTranslation();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleClick = async (e) => {
    var link = e.currentTarget.toString();
    if (link.includes("data-tables")) {
      e.preventDefault();
    } else if (link.includes("support")) {
      e.preventDefault();
      openInNewTab("https://wa.me/972559995677");
    }
  };

  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let nonActiveIcon = "#A3AED070";

  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <React.Fragment key={route.name}>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt="18px"
              pb="12px"
              key={index}
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </React.Fragment>
        );
      } else if (
        (route.layout === "/admin" ||
          route.layout === "/auth" ||
          route.layout === "/rtl") &&
          route.name !== "Signin" &&
          route.name !== "Otp" &&
          (route.hide === false || route.hide === undefined)
      ) {
        let active = activeRoute(route.path.toLowerCase());

        return (
          <NavLink
            key={index}
            to={route.layout + route.path}
            onClick={handleClick}
          >
            <VStack spacing="0" margin={'10px'}>
              <Box mt={'5px'} color={active ? activeIcon : nonActiveIcon}>
                {route.icon}
              </Box>
              <Text
                fontSize="l"
                color={active ? activeColor : nonActiveIcon}
                fontWeight={active ? "bold" : "normal"}>
                {t(route.name)}
              </Text>
            </VStack>
          </NavLink>
        );
      } else {
        return null;
      }
    });
  };
  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderTopColor: '#A3AED050',
    borderTopWidth: '1px'
  };

  return (
    <VStack
      zIndex="sticky"
      bg="white"
      pos="fixed"
      bottom="0"
      h="16"
      w="full"
      justify="center" height={'80px'}
      style={styles}>
      <Flex width={'100%'} direction={'row'} justifyContent={'space-evenly'}>{createLinks(routes)}</Flex>
    </VStack>
  );
};

export default BottomNavbar;
