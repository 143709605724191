// Chakra imports
import {
    Box,
    Flex,
    Text,
    useColorModeValue,
    Button,
    SimpleGrid,
    useDisclosure,
} from "@chakra-ui/react";
  // Custom components
import Card from "components/card/Card.js";
import { useTranslation } from 'react-i18next';
import React, {useEffect} from "react";
import CustomInput from "../../../../components/customInput/CustomInput";
import {LogEvent, LogEventParams} from "../../../../analytics/analytics";
import TransferModal from "../../transferFlow/TransferModal";

export default function WithdrawalRequest(props) {
    const { details, callBack } = props;
      // Chakra Color Mode

    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const [sliderValue, setSliderValue] = React.useState(100)
    const [finalSliderValue, setFinalSliderValue] = React.useState(100)
    const [maxAmount, setMaxAmount] = React.useState((details && details.availableToTransfer) ? Math.floor(details.availableToTransfer) : 0)

    useEffect(() => {
        if(details && details.availableToTransfer && details.availableToTransfer > 0) {
            setMaxAmount(Math.floor(details.availableToTransfer))
        }
    }, [details.availableToTransfer]);


    const {
        isOpen: isTransferFlowOpen,
        onOpen: onTransferFlowOpen,
        onClose: onTransferFlowClose
    } = useDisclosure()



    function handleConfirm(event) {
        if(finalSliderValue > details.availableToTransfer) {
            onTransferFlowOpen()
        } else {
            onTransferFlowOpen()
        }
    }

        const handleSliderValueChange = (value) => {
        setFinalSliderValue(value)
    };

    const { t } = useTranslation();
    return (
      <Card px='0px' align='center'>
        <Flex px='25px' justify='space-between' align='center'>
          <Text
            color={textColorPrimary}
            fontSize={'18px'}
            lineHeight='100%'>
            {t('main_card_3_title')}
          </Text>
        </Flex>
        <Flex mt={'15px'} py='20px' px='25px' justify='center' align='center'>
            <CustomInput value={sliderValue} max={maxAmount} onChange={handleSliderValueChange} />
        </Flex>

        <Box
        px='25px'
        py='0px'
        w='100%' mt='15px' >
        <SimpleGrid columns={2} spacing={5}>
            <Button
                me='100%'
                w='140px'
                variant='lightBrand'
                fontWeight='500'
                onClick={() => {
                    LogEventParams("transfer_amount_button_fixed", { "value": 200 })
                    setSliderValue(200);
                }}>
                ₪200
            </Button>
            <Button
                me='100%'
                w='140px'
                variant='lightBrand'
                fontWeight='500'
                onClick={() => {
                    LogEventParams("transfer_amount_button_fixed", { "value": 100 })
                    setSliderValue(100);
                }}>
                ₪100
            </Button>
            <Button
                me='100%'
                w='140px'
                variant='lightBrand'
                fontWeight='500'
                onClick={() => {
                    LogEventParams("transfer_amount_button_fixed", { "value": "max" })
                    setSliderValue(details.availableToTransfer);
                }}>
                ₪{details.availableToTransfer}
            </Button>
            <Button
                me='100%'
                w='140px'
                variant='lightBrand'
                fontWeight='500'
                onClick={() => {
                    LogEventParams("transfer_amount_button_fixed", { "value": "300" })
                    setSliderValue(300);
                }}>
                ₪300
            </Button>
        </SimpleGrid>

            <TransferModal amount={finalSliderValue} isOpen={isTransferFlowOpen} onClose={onTransferFlowClose}/>

            <Box py='25px'>
            <Button h='50px'
                    w='100%'
                    variant='brand'
                    fontWeight='700'
                    fontSize='20px'
                    onClick={handleConfirm}>
                    {t('main_card_3_button')}
                </Button>
            </Box>
        </Box>
      </Card>
    );
}
