import {Box, Grid, Text, Flex} from "@chakra-ui/react";
import React, {useEffect} from "react";
import AttendanceTable from "./components/AttendanceTable";
import FasterFadeTransition from "../../../components/pageTransition/FasterFadeTransition";
import {LogEvent} from "../../../analytics/analytics";
import {useTranslation} from "react-i18next";
import {getMobileOperatingSystem, Mobile} from "../../../utils/DeviceUtils";

export default function Attendance() {

    const { t } = useTranslation();
    useEffect(() => {
        LogEvent("shifts_view")
    }, []);

    const mobileOs = getMobileOperatingSystem();

    return (
    <FasterFadeTransition>
        <Box pt={{ base: "50px", md: "80px", xl: "80px" }} pb={mobileOs === Mobile.Android ? "50px" : "80px"}>
        <AttendanceTable />
            <Flex marginTop={'20px'} marginRight={'10px'} marginLeft={'10px'} direction={'column'}>
                <Text margin={'0px'} fontSize={'14px'}>{t('shifts_view_comment_1')}</Text>
                <Text marginTop={'15px'} fontSize={'14px'}>{t('shifts_view_comment_2')}</Text>
            </Flex>
      </Box>
    </FasterFadeTransition>
  );
}
