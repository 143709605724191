import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Box, Stack, Select, Input, Icon, Checkbox
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {FontName} from "../../../utils/Font";
import {useTranslation} from "react-i18next";
import Lottie from "lottie-react";
import waiting from "../../../assets/lottie/waiting-transfer.json"
import loading from "../../../assets/lottie/loading-animation.json"
import success1 from "../../../assets/lottie/success1-animation.json"
import comingsoon from "../../../assets/lottie/coming-soon-rocket.json"
import {banks, prettifyBankNameById} from "../../../utils/BankUtils";
import {AnimatePresence, motion, AnimateSharedLayout} from "framer-motion";
import TransfersApi from "../../../api/transfers";
import { validateBankAccount, RESULT } from "israeli-bank-validation";
import UsersApi from "../../../api/users";
import MockApi from "../../../api/mockApi";
import {IoIosClose} from "react-icons/io";
import {LogEvent, LogEventParams} from "../../../analytics/analytics";
import validator from "il-bank-account-validator"

const PageState = {
    None: 0,
    Error: 1,
    Loading: 2,
    Initial: 3,
    TransferPrep : 4,
    MissingBankAccountDetails: 5,
    ConfirmOTP: 6,
    TransferCompleted: 7,
    ServiceNotActive: 8
}

export default function TransferModal(props) {
    const { isOpen, onClose, amount } = props;
    const { t } = useTranslation();
    const [pageState, setPageState] = React.useState(undefined)
    const [messageText, setMessageText] = React.useState("");
    const [subtitleText, setSubtitleText] = React.useState("")
    const [transferAmount, setTransferAmount] = React.useState(amount)
    const [banksList, setBanksList ] = React.useState(banks)
    const [bankId, setBankId] = React.useState("")
    const [branchId, setBranchId] = React.useState("")
    const [accountId, setAccountId] = React.useState("")
    const firstField = React.useRef()
    const [otpCode, setOtpCode] = useState("");
    const [requestId, setRequestId] = useState("");
    const [bankAccountDetailsText , setBankAccountDetailsText] = React.useState("")
    const [title, setTitle] = useState("");
    const [error, setError] = useState(undefined);
    const innerPadding = '15px'
    const [approveError, setApproveError] = useState(undefined);
    const [approved, setApproved] = useState(false)

    useEffect(() => {
        setTransferAmount(amount)
    }, [amount]);

    useEffect(() => {
        if(isOpen) {
            setPageState(PageState.Initial)
        } else {
            setPageState(PageState.None)
        }
    }, [isOpen]);

    useEffect(() => {
        adjustPageState(pageState)
    }, [pageState]);

    function adjustPageState(newPageState) {
        switch (newPageState) {
            case PageState.Initial: {
                LogEvent('transfer_flow_start')
                prepareApplication()
                setRequestId("")
                setError(undefined)
                setApproveError(undefined)
                setApproved(false)
                setBankId("")
                setBranchId("")
                setAccountId("")
                setOtpCode("")
                setRequestId("")
            }
            break;
            case PageState.TransferPrep:
            {
                /**
                 * After confirming bank details and show transfer amount + transfer fee
                 */
                setMessageText(t('application_transfer_message'))
                LogEventParams('transfer_flow_details' , { "value": amount })
            }
            break;
            case PageState.MissingBankAccountDetails:
            {
                LogEvent('transfer_flow_bank_details')
                setMessageText(t('transfer_request_missing_bank_details'))
            }
            break;
            case PageState.TransferCompleted:
            {
                setMessageText(t('transfer_request_completed'))
                LogEvent('transfer_flow_completed')
            }
            break;
            case PageState.Error:
            {
            }
            break;
            case PageState.ConfirmOTP:
            {
                LogEvent('transfer_flow_confirm_otp')
            }
            break;
            default:
                break
        }
    }

    function handleNext() {
        switch (pageState) {
            case PageState.TransferPrep: {
                createApplication()
            }
                break;
            case PageState.MissingBankAccountDetails: {
                confirmBad()
            }
            break;
            case PageState.ConfirmOTP: {
                approveApplication()
            }
            break;
            case PageState.ServiceNotActive:
            case PageState.TransferCompleted: {
                onClose()
            }
                break;
            default:
                break
        }
    }

    function confirmBad() {

        if(!bankId) {
            setError(t('transfer_request_missing_bank'))
        }
        else if(!branchId) {
            setError(t('transfer_request_missing_branch'))
        }
        else if(!accountId) {
            setError(t('transfer_request_missing_account'))
        } else {

            const validationResponse = validateBankAccount(bankId, branchId, accountId);
            const validationResponseDouble = validator(bankId, branchId, accountId)

            if (validationResponse === RESULT.NOT_VALID && !validationResponseDouble) {
                setError(t('transfer_request_invalid_bank_details'))
            } else if(approved === false) {
                setError(undefined)
                setApproveError(t('transfer_request_bank_details_checkbox_error'))
            } else {
                setError(undefined)
                setApproveError(undefined)
                if(MockApi.IsActive) {
                    setSubtitleText(t('application_transfer_comment_message').replace("[1]", "7.99"))
                    setMessageText(t('application_transfer_message'))
                    setPageState(PageState.TransferPrep)
                } else {
                    setPageState(PageState.Loading)
                    LogEvent('transfer_flow_bank_details_confirm')
                    UsersApi.updateBankAccountInfo(bankId, branchId, accountId).then(res => {
                        prepareApplication()
                    }).catch(e => {
                        setMessageText(t('transfer_request_unexpected_error'))
                        setPageState(PageState.TempError)
                    })
                }
            }
        }
    }

    function prepareApplication() {
        setError(undefined)
        setPageState(PageState.Loading)
        TransfersApi.prepApplication(transferAmount).then(res => {
            const response = res.data

            const errors = response["errors"]
            if(errors && errors.length > 0) {
                const missingEmployeeInformation = errors.filter(error => error.code === 202);
                const serviceNotActive = errors.filter(error => error.code === 203);
                const missingEmployerInformation = errors.filter(error => error.code === 201);
                const notEnoughPayError = errors.filter(error => error.code === 100);
                const minimumTransferError = errors.filter(error => error.code === 113);
                if(missingEmployeeInformation.length > 0) {
                    setMessageText(t('transfer_request_missing_bank_details'))
                    setPageState(PageState.MissingBankAccountDetails)
                } else if(serviceNotActive.length) {
                    setPageState(PageState.ServiceNotActive)
                    LogEvent('transfer_flow_error_service_not_active')
                } else if(missingEmployerInformation.length) {
                    setTitle("שגיאה")
                    setMessageText(t('transfer_request_failed'))
                    setPageState(PageState.Error)
                    LogEvent('transfer_flow_error_201')
                } else if(notEnoughPayError.length > 0) {
                    setTitle(t('transfer_request_failed_error_title'))
                    setMessageText(t('transfer_request_failed_error_message'))
                    setPageState(PageState.Error)
                    LogEvent('transfer_flow_error_not_enough_pay')
                } else if(minimumTransferError.length > 0) {
                    setTitle(t('transfer_request_minimum_error_title'))
                    setMessageText(t('transfer_request_minimum_error_message'))
                    LogEvent('transfer_flow_error_minimum')
                    setPageState(PageState.Error)
                }
            } else {
                if(response["bankAccountDetails"]) {
                    let bankAccountDetails = t('application_prep_bank_account_details')
                    bankAccountDetails = bankAccountDetails.replace("[1]", prettifyBankNameById(response.bankAccountDetails.bankId))
                    bankAccountDetails = bankAccountDetails.replace("[2]", response.bankAccountDetails.branchId)
                    bankAccountDetails = bankAccountDetails.replace("[3]", response.bankAccountDetails.accountId)
                    setBankAccountDetailsText(bankAccountDetails)
                }
                setSubtitleText(t('application_transfer_comment_message').replace("[1]", response.transferFee))
                setMessageText(t('application_transfer_message'))
                setPageState(PageState.TransferPrep)
            }
        }).catch(e => {
            //onConfirmModalClose()
            //setShowLoading(false)
        })

    }

    function createApplication() {
        setPageState(PageState.Loading)
        TransfersApi.createNewTransfer(transferAmount).then(res => {
            const response = res.data
            const requestId = response.requestId;
            setRequestId(requestId)
            setPageState(PageState.ConfirmOTP)
        }).catch(e => {
        })
    }


    function approveApplication() {
        if(!otpCode) {
            setError(t('login_2_textfield_valid_error_text'))
        } else {
            setError(undefined)
            setPageState(PageState.Loading)
            TransfersApi.approve(requestId, otpCode).then(res => {
                setPageState(PageState.TransferCompleted)
            }).catch(e => {
                setPageState(PageState.ConfirmOTP)
                setError(t('transfer_request_otp_invalid'))
            })
        }
    }

    function noneContent() {
        return (
            <>
                <Text fontWeight='bold' marginTop={'20px'}>
                    None Content
                </Text>
            </>
        )
    }

    function errorContent() {
        return (
            <>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }} >

                    <Flex alignItems={'stretch'}
                          justifyContent={'space-around'}
                          direction={'column'}
                    >
                        <Text fontWeight='bold'
                              marginTop={'30px'}
                              fontSize={'35px'}
                              fontFamily={FontName}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}>
                            {title}
                        </Text>

                        <Text fontWeight='300'
                              marginTop={'30px'}
                              fontSize={'22px'}
                              fontFamily={FontName}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}>
                            {messageText}
                        </Text>

                        <Flex padding={'10px'}>
                            <Button
                                marginTop={'50px'}
                                h='50px'
                                w='100%'
                                fontFamily={FontName}
                                variant='brand'
                                fontWeight='700'
                                fontSize='20px'
                                onClick={onClose}>
                                {t('main_card_3_button')}
                            </Button>
                        </Flex>

                    </Flex>
                </motion.div>
            </>
        )
    }

    function serviceNotActive() {
        return (
            <>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }} >

                    <Flex alignItems={'stretch'}
                          justifyContent={'space-around'}
                          direction={'column'}
                    >
                        <Text fontWeight='bold'
                              marginTop={'30px'}
                              fontSize={'32px'}
                              fontFamily={FontName}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}>
                            {t('service_not_active_title')}
                        </Text>

                        <Text fontWeight='300'
                              marginTop={'30px'}
                              fontSize={'22px'}
                              fontFamily={FontName}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}>
                            {t('service_not_active_message_1')}
                        </Text>

                        <Text fontWeight='300'
                              marginTop={'30px'}
                              fontSize={'22px'}
                              fontFamily={FontName}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}>
                            {t('service_not_active_message_2')}
                        </Text>


                        <Box marginTop={'0px'} >
                            <Lottie
                                animationData={comingsoon}
                                style={{ height: 380}}
                                loop={true} />
                        </Box>


                        <Flex padding={'10px'}>
                            <Button
                                marginTop={'0px'}
                                h='50px'
                                w='100%'
                                fontFamily={FontName}
                                variant='brand'
                                fontWeight='700'
                                fontSize='20px'
                                onClick={handleNext}>
                                {t('service_not_active_button')}
                            </Button>
                        </Flex>
                    </Flex>
                </motion.div>
            </>
        )
    }

    function loadingContent() {
        return (
            <>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }} >

                    <Flex alignItems={'stretch'}
                          justifyContent={'space-around'}
                          direction={'column'}
                    >
                        <Flex
                            justifyContent={'space-between'}
                            direction={'row'}
                            backgroundColor={'transparent'}
                            width={'100%'}>
                            <Text padding={innerPadding}
                                  mt={'35px'}
                                  fontWeight='bold'
                                  fontSize={'35px'}
                                  fontFamily={FontName}>
                                {t('loading')}
                            </Text>
                            <Button mt={'10px'}
                                    w={'45px'} h={'45px'} onClick={onClose}>
                                <Icon  w='45px' h='45px' as={IoIosClose} />
                            </Button>
                        </Flex>

                        <Box marginTop={'150px'} >
                            <Lottie
                                animationData={waiting}
                                style={{ height: 200}}
                                loop={true} />
                        </Box>


                    </Flex>
                </motion.div>
            </>
        )
    }

    function transferPrep() {
        return (
            <>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }} >

                <Flex alignItems={'stretch'}
                          justifyContent={'space-around'}
                          direction={'column'}
                    >
                    <Flex
                        justifyContent={'space-between'}
                        direction={'row'}
                        backgroundColor={'transparent'}
                        width={'100%'}>
                        <Text padding={innerPadding}
                              mt={'35px'}
                              fontWeight='bold'
                              fontSize={'35px'}
                              fontFamily={FontName}>
                            {t('transfer_request_title')}
                        </Text>
                        <Button mt={'10px'}
                                w={'45px'} h={'45px'} onClick={onClose}>
                            <Icon  w='45px' h='45px' as={IoIosClose} />
                        </Button>
                    </Flex>

                    <Text fontWeight='300'
                          marginTop={'30px'}
                          fontSize={'22px'}
                          fontFamily={FontName}
                          paddingRight={innerPadding}
                          paddingLeft={innerPadding}>
                        {messageText}
                    </Text>

                    <Text fontWeight='700'
                              marginTop={'30px'}
                              fontSize={'50px'}
                              fontFamily={FontName}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}>
                            ₪{transferAmount}
                        </Text>

                        <Text fontWeight='200'
                              marginTop={'30px'}
                              fontSize={'18px'}
                              fontFamily={FontName}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}>
                            {subtitleText}
                        </Text>

                    { bankAccountDetailsText &&
                        <Text fontWeight='200'
                                                      marginTop={'30px'}
                                                      fontSize={'18px'}
                                                      fontFamily={FontName}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}>
                        * {bankAccountDetailsText}
                    </Text>}



                    <Flex padding={'10px'}>
                        <Button
                            marginTop={'30px'}
                            h='50px'
                            w='100%'
                            fontFamily={FontName}
                            variant='brand'
                            fontWeight='700'
                            fontSize='20px'
                            onClick={handleNext}>
                            {t('main_card_3_button')}
                        </Button>
                    </Flex>
                    </Flex>
                </motion.div>
            </>
        )
    }

    const onOtpInput = (e) => {
        if (e.target.value.length > e.target.maxLength)
            e.target.value = e.target.value.slice(0, e.target.maxLength);
    };

    function confirmOtp() {
        return (
            <>
                <motion.div
                    layout
                    initial={{ opacity: 0.5, x: "100vw" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0.5, x: "-100vw", transition: { duration: 0.2 } }}
                >

                <Flex alignItems={'stretch'}
                      justifyContent={'space-around'}
                      direction={'column'}
                >
                    <Flex
                        justifyContent={'space-between'}
                        direction={'row'}
                        backgroundColor={'transparent'}
                        width={'100%'}>
                        <Text padding={innerPadding}
                              mt={'35px'}
                              fontWeight='bold'
                              fontSize={'35px'}
                              fontFamily={FontName}>
                            {t('transfer_request_otp_title')}
                        </Text>
                        <Button mt={'10px'}
                                w={'45px'} h={'45px'} onClick={onClose}>
                            <Icon  w='45px' h='45px' as={IoIosClose} />
                        </Button>
                    </Flex>

                    <Text fontWeight='300'
                          marginTop={'30px'}
                          fontSize={'22px'}
                          fontFamily={FontName}
                          paddingRight={innerPadding}
                          paddingLeft={innerPadding}>
                        {t('transfer_request_otp_message')}
                    </Text>

                    <Text fontWeight='300'
                          textColor={'red'}
                          marginTop={'15px'}
                          fontSize={'22px'}
                          fontFamily={FontName}
                          paddingRight={innerPadding}
                          paddingLeft={innerPadding}>
                        {error}
                    </Text>

                    <Flex padding={'10px'}>
                        <Input
                            marginTop={'40px'}
                            textAlign="center"
                            justifyContent="center"
                            isRequired={true}
                            type="number"
                            maxLength="6"
                            placeholder={t("login_2_textfield_hint")}
                            onInput={onOtpInput}
                            variant="auth"
                            fontSize="32px"
                            ms={{ base: "0px", md: "0px" }}
                            mb="24px"
                            fontWeight="500"
                            fontFamily={FontName}
                            height="50px"
                            borderColor={"secondaryGray.600"}
                            onChange={(event) => {
                                setOtpCode(event.target.value);
                            }}
                        />
                    </Flex>


                    <Flex padding={'10px'}>
                        <Button
                            marginTop={'30px'}
                            h='50px'
                            w='100%'
                            fontFamily={FontName}
                            variant='brand'
                            fontWeight='700'
                            fontSize='20px'
                            onClick={handleNext}>
                            {t('main_card_3_button')}
                        </Button>
                    </Flex>

                </Flex>
                </motion.div>
            </>
        )
    }

    const onChange = (event) => {
        setApproved(event.target.checked)
    }

    function missingBankInformation() {
        return (
            <>
                <motion.div
                    initial={{ opacity: 0.5, x: "100vw" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0.5, x: "-100vw", transition: { duration: 0.2 } }}
                >
                <Flex alignItems={'stretch'}
                      justifyContent={'space-around'}
                      direction={'column'}>
                    <Flex
                          justifyContent={'space-between'}
                          direction={'row'}
                          backgroundColor={'transparent'}
                          width={'100%'}>
                        <Text padding={innerPadding}
                            mt={'35px'}
                              fontWeight='bold'
                              fontSize={'35px'}
                              fontFamily={FontName}>
                            {t('transfer_request_missing_bank_details_title')}
                        </Text>
                        <Button mt={'10px'}
                                w={'45px'} h={'45px'} onClick={onClose}>
                            <Icon  w='45px' h='45px' as={IoIosClose} />
                        </Button>
                    </Flex>

                    <Text fontWeight='300'
                          marginTop={'20px'}
                          fontSize={'22px'}
                          fontFamily={FontName}
                          paddingRight={innerPadding}
                          paddingLeft={innerPadding}>
                        {messageText}
                    </Text>

                    <Text fontWeight='300'
                          textColor={'red'}
                          marginTop={'15px'}
                          fontSize={'16px'}
                          fontFamily={FontName}
                          paddingRight={innerPadding}
                          paddingLeft={innerPadding}>
                        {error}
                    </Text>

                    <Flex marginRight={'20px'} marginTop={'10px'}
                          padding={innerPadding}>
                        <Stack spacing='24px'>
                            <Box>
                                <Select fontFamily={FontName}
                                        fontSize={'22px'}
                                    id='bankId' defaultValue={'default'} onChange={(event) => {
                                    setBankId(event.target.value);
                                }}>
                                    <option value='default'>{t('select_bank')}</option>
                                    {
                                        banksList.map((obj) =>
                                            <option value={obj.id} key={obj.id}>{obj.name}</option>
                                        )
                                    }
                                </Select>
                            </Box>

                            <Box>
                                <Input
                                    fontFamily={FontName}
                                    fontSize={'22px'}
                                    ref={firstField}
                                    id='branchId'
                                    type="number"
                                    placeholder={t('enter_branch')}
                                    onChange={(event) => {
                                        setBranchId(event.target.value);
                                    }}
                                />
                            </Box>

                            <Box>
                                <Input
                                    fontFamily={FontName}
                                    fontSize={'22px'}
                                    ref={firstField}
                                    id='accountId'
                                    type="number"
                                    placeholder={t('enter_account_id')}
                                    onChange={(event) => {
                                        setAccountId(event.target.value);
                                    }}
                                />
                            </Box>

                        </Stack>
                    </Flex>


                    {
                        approveError && !approved && <Text
                            style={{
                                fontSize: "16px",
                                color: "red",
                                textAlign: "right",
                                fontWeight: 400,
                            }}
                            paddingRight={innerPadding}
                            paddingLeft={innerPadding}>
                            {approveError}
                        </Text>
                    }

                    <Checkbox marginTop={'15px'}
                              paddingRight={innerPadding}
                              paddingLeft={innerPadding}
                              onChange={onChange} >
                        <Text fontSize={'14px'}>{t('transfer_request_bank_details_checkbox')}</Text>
                    </Checkbox>

                    <Flex padding={'12px'}>
                        <Button
                            marginTop={'30px'}
                            h='50px'
                            w='100%'
                            fontFamily={FontName}
                            variant='brand'
                            fontWeight='700'
                            fontSize='20px'
                            onClick={handleNext}>
                            {t('main_card_3_button')}
                        </Button>
                    </Flex>
                </Flex>
                </motion.div>
            </>
        )
    }

    function transferCompleted() {
        return (
            <>
                <motion.div
                    initial={{ opacity: 0.5, x: "100vw" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0.5, x: "-100vw", transition: { duration: 0.2 } }}
                >
                <Flex alignItems={'stretch'}
                      justifyContent={'space-around'}
                      direction={'column'}>
                    <Flex
                        justifyContent={'space-between'}
                        direction={'row'}
                        backgroundColor={'transparent'}
                        width={'100%'}>
                        <Text padding={innerPadding}
                              mt={'35px'}
                              fontWeight='bold'
                              fontSize={'30px'}
                              fontFamily={FontName}>
                            {t('transfer_request_success')}
                        </Text>
                    </Flex>

                    <Text fontWeight='300'
                          marginTop={'30px'}
                          fontSize={'22px'}
                          fontFamily={FontName}
                    paddingRight={innerPadding}
                    paddingLeft={innerPadding}>
                        {messageText}
                    </Text>

                    <Box marginTop={'50px'} >
                        <Lottie
                            animationData={success1}
                            style={{ height: 300}}
                            loop={false} />
                    </Box>

                    <Flex padding={'12px'}>
                        <Button
                            marginTop={'30px'}
                            h='50px'
                            w='100%'
                            fontFamily={FontName}
                            variant='brand'
                            fontWeight='700'
                            fontSize='20px'
                            onClick={handleNext}>
                            {t('main_card_3_button')}
                        </Button>
                    </Flex>
                </Flex>
                </motion.div>
            </>
        )
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered>
                <ModalOverlay />
                <ModalContent minH="100%" maxW="100%">
                    <ModalBody padding={'10px'}>
                        <AnimatePresence>
                        </AnimatePresence>
                        {pageState === PageState.None && noneContent()}
                        {pageState === PageState.Loading && loadingContent()}
                        {pageState === PageState.Error && errorContent()}
                        {pageState === PageState.ServiceNotActive && serviceNotActive()}
                        {pageState === PageState.TransferPrep && transferPrep()}
                        {pageState === PageState.ConfirmOTP && confirmOtp()}
                        {pageState === PageState.MissingBankAccountDetails && missingBankInformation()}
                        {pageState === PageState.TransferCompleted && transferCompleted()}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}