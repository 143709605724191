import { mode } from "@chakra-ui/theme-tools";
export const textStyles = {
    components: {
        Text: {
            variants: {
                abc: (props) => ({
                    field: {
                        textColor: 'red',
                        color: 'blue',
                    },
                }),
            },
        },
    },
};
