// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "#111C44 !important",
    "white !important"
  );
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Avatar
        mx='auto'
        src={avatar}
        h='120px'
        w='120px'
        mt='0px'
        border='2px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize='xl'>
        {job}
      </Text>
    </Card>
  );
}
