/* eslint-disable */
import {
    Flex, IconButton,
    Progress,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr, Button, Box,
    useColorModeValue, Tfoot,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, {useEffect, useMemo, useState} from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import {HiOutlineChevronLeft, HiOutlineChevronRight} from "react-icons/hi";
import {BsCalendar4Week} from "react-icons/bs";
import {columnsDataDevelopment} from "../variables/columnsData"
import {
    convertShiftWorkingHoursToHours,
    convertTotalWorkingHoursToHours,
    getCurrentDateForAttendance,
    getCurrentMonthTranslationKey,
    getDateForAttendanceHeader,
    prettifyDate
} from "../../../../utils/dateUtils";
import ReactSession from "../../../../utils/ReactSession";
import { useTranslation } from "react-i18next";
import AuthApi from "../../../../api/auth";
import {handleLogout} from "../../../../utils/Logout";
import UsersApi from "../../../../api/users";

export default function AttendanceTable(props) {
    // const { columnsData, tableData } = props;
    //
    const columns = useMemo(() => columnsDataDevelopment, [columnsDataDevelopment]);
    const [data, setData] = useState([]);
    const [totalPay, setTotalPay] = useState(0)
    const [totalHours, setTotalHours] = useState(0)
    const { t } = useTranslation();

    useEffect(() => {
        getShifts()
    }, [])

    const getShifts = () => {
        UsersApi.getShifts(getCurrentDateForAttendance())
            .then((res) => {

                if(res.data) {
                    if(res.data.totalPay && res.data.totalPay > 0) {
                        setTotalPay(res.data.totalPay)
                    } else {
                        setTotalPay(0)
                    }
                    if(res.data.totalWorkingHours && res.data.totalWorkingHours > 0) {
                        setTotalHours(convertTotalWorkingHoursToHours(res.data.totalWorkingHours))
                    } else {
                        setTotalHours(0)
                    }

                    const timeReporting = res.data.shifts;
                    if(timeReporting) {
                        timeReporting.sort(function(a,b){
                            return new Date(b.startTime) - new Date(a.startTime);
                        });

                        let array = []
                        if(timeReporting) {
                            for (let i = 0; i < timeReporting.length; i++) {
                                let shift = {
                                    date: prettifyDate(timeReporting[i].shiftDate),
                                    pay: timeReporting[i].totalEarned,
                                }
                                if(timeReporting[i].startTime === undefined) {
                                    shift['workingHours'] = t('shifts_missing_enter');
                                } else {
                                    shift['startTime'] = prettifyDate(timeReporting[i].startTime);
                                }
                                if(timeReporting[i].endTime === undefined){
                                    shift['workingHours'] = t('shifts_missing_exit');
                                } else {
                                    shift['endTime'] = prettifyDate(timeReporting[i].endTime);
                                }
                                if(timeReporting[i].workingHours > 0) {
                                    shift['workingHours'] = convertShiftWorkingHoursToHours(timeReporting[i].workingHours)
                                }
                                array.push(shift);
                            }
                        }
                        setData(array);
                    }

                }

            })
            .catch((e) => {
            });
    };

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 50;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const iconColor = useColorModeValue("secondaryGray.500", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandColor = useColorModeValue("brand.500", "white");

    return (

        <Flex mt={'-10px'} direction={'column'}>
            <Card
                direction='column'
                w='100%'
                px='0px'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Flex direction='row' px='10px' justifyContent='space-between' mb='20px' align='center'>
                    <IconButton icon={<HiOutlineChevronRight fontSize={'28px'} />}  aria-label={'next'}/>

                    <Button  _hover={brandColor} fontSize={'18px'} rightIcon={<BsCalendar4Week  fontSize={'15px'} />}>
                        {t(getCurrentMonthTranslationKey()) + ' ' + new Date().getFullYear()}
                    </Button>

                    <IconButton icon={<HiOutlineChevronLeft fontSize={'28px'} />}  aria-label={'previous'}/>

                </Flex>
                <Table {...getTableProps()} variant='simple' color='gray.500' >
                    <Thead>
                        {headerGroups.map((headerGroup, index) => (
                            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <Th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={index}
                                        borderColor={borderColor}>
                                        <Flex
                                            justifyContent='center'
                                            alignItems='center'
                                            color='gray.600'
                                            backgroundColor={'transparent'}>
                                            <Text color={textColor} fontSize='14px'
                                            textAlign={'center'}>
                                                {t(column.render("Header"))}
                                            </Text>
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        let cellWidth = cell.column.width;
                                        if (cell.column.Header === "header_sum") {
                                            data = (
                                                <Text color={textColor} fontSize='16px'>
                                                    ₪{cell.value}
                                                </Text>
                                            );
                                        } else if (cell.column.Header === "header_date") {
                                            data = (
                                                <Flex direction={'column'} alignItems={'center'}>
                                                    <Text color={textColor} fontSize='16px'>
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                            );
                                        } else if (cell.column.Header === "כניסה" || cell.column.Header === "יציאה") {
                                            data = (
                                                    <Text
                                                        me='0px'
                                                        color={textColor}
                                                        fontSize='16px'>
                                                        {cell.value}
                                                    </Text>
                                            );
                                        } else if (cell.column.Header === "header_total_hours") {
                                            data = (
                                                <Text
                                                    me='0px'
                                                    color={textColor}
                                                    fontSize='16px' align={'center'}>
                                                    {cell.value}
                                                </Text>
                                            );
                                        }
                                        return (
                                            <Td
                                                {...cell.getCellProps()}
                                                key={index}
                                                fontSize={{ m: "16px", sm: "14px" }}
                                                minWidth={cellWidth}
                                                width={cellWidth}
                                                borderColor='transparent'
                                                backgroundColor={'transparent'}>
                                                <Flex
                                                    flexDirection={'row'}
                                                    alignItems={'center'}
                                                    justifyContent={'center'}
                                                    backgroundColor={'transparent'}>
                                                 {data}
                                                </Flex>
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                    <Tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()} >
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()} >
                                    <Flex justifyContent={'center'}  mt={'10px'}>
                                        <Text
                                            marginRight={column.Footer === 'header_total_hours' ? '0px' : '0px'}
                                            fontWeight={'bold'}
                                            textColor={'black'}
                                            fontSize={'22px'}>
                                            {
                                                column.Footer === 'TotalHours' ? totalHours :
                                                column.Footer === 'TotalPay' ? '₪'+totalPay :
                                                column.Footer === 'Summary' ? t("footer_summary") : null
                                            }
                                        </Text>
                                    </Flex>
                                </td>
                            ))}
                        </tr>
                    ))}
                    </Tfoot>
                </Table>
            </Card>
        </Flex>
    );
}
