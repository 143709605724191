export const TagStatus = 0
export const TagAmount = 1
export const TagDate = 2

export const withdrawalsHistoryColumns = [
    {
      Header: "header_status",
      tag: TagStatus,
      accessor: "status",
    },
    {
      Header: "header_amount",
      tag: TagAmount,
      accessor: "amount",

    },
    {
      Header: "header_date",
      tag: TagDate,
      accessor: "createdDate",
    }
  ];

export const withdrawalsHistoryData = [
    {
      "status": 1,
      "amount": "₪500",
      "date": "22/7/2022"
    },
    {
      "status": 1,
      "amount": "₪150",
      "date": "12/7/2022"
    },
    {
      "status": 1,
      "amount": "₪400",
      "date": "12/7/2022"
    },
    {
      "status": 1,
      "amount": "₪400",
      "date": "20/6/2022"
    },
  ];
  