import { useTranslation } from "react-i18next";

export const matchDateFormatToBackend = (dateString) => {
    const [sYear, sMonth, sDay] = dateString.split('-');
    const dateObj = new Date(+sYear, +sMonth - 1, +sDay);
    const dateAsBackendFormat = ("0" + dateObj.getDate()).slice(-2) + "/" + ("0"+(dateObj.getMonth()+1)).slice(-2) + "/" +
        dateObj.getFullYear();
    return dateAsBackendFormat;
}

export const prettifyDate = (date) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("he-IL");// + " " + dateObj.toLocaleTimeString("he-IL");
}

export const prettifyPayPeriodDate = (date) => {
    const [sDay, sMonth, sYear] = date.split('/');
    const dateObj = new Date(+sYear, +sMonth - 1, +sDay);
    const month = dateObj.toLocaleString('he-IL', { month: 'long', year: 'numeric'});
    return month;
}

export const getCurrentMonthTranslationKey = () => {
    const dateObj = new Date();
    return (dateObj.getMonth()+1)+'_month'
}

export const getCurrentDateForAttendance = () => {
    const dateObj = new Date();
    return ("0"+(dateObj.getMonth()+1)).slice(-2) + '/' + dateObj.getFullYear()
}

export const convertShiftWorkingHoursToHours = (workingHours) => {
    try {
        if(workingHours <= 0) {
            return null
        }
        var date = new Date(0,0);
        date.setMinutes(+workingHours * 60);
        return date.toTimeString().slice(0, 5);
    } catch (e) {
        return workingHours;
    }
}

export const convertTotalWorkingHoursToHours = (workingHours) => {
    try {
        var decimalTime = parseFloat(workingHours);
        decimalTime = decimalTime * 60 * 60;
        var hours = Math.floor((decimalTime / (60 * 60)));
        decimalTime = decimalTime - (hours * 60 * 60);
        var minutes = Math.floor((decimalTime / 60));

        if(hours < 10)
        {
            hours = "0" + hours;
        }
        if(minutes < 10)
        {
            minutes = "0" + minutes;
        }

        return ("" + hours + ":" + minutes);
    } catch (e) {
        return workingHours;
    }
}