import axios from "./index";
import MockApi from "./mockApi";
import {API} from "./env";

export default class UsersApi {

    static updateBankAccountInfo = (bankId,
                        branchId,
                        accountId) => {
        if(MockApi.IsActive) {
            return MockApi.UpdateBankAccountInfo();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const data = JSON.stringify({
            "bankId": bankId,
            "accountId": accountId,
            "branchId": branchId
        });

        return axios.post(`${API}/user/bad`, data, config);
    };


    static getShifts = (date) => {
        if(MockApi.IsActive) {
            return MockApi.UpdateBankAccountInfo();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const data = JSON.stringify({
            "date": date
        });

        return axios.post(`${API}/shifts`, data, config);
    };
}
