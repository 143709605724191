// Create Slide Transition using Framer motion chakra ui

import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

const SlideTransition = ({ children }) => {
  return (
    <Box
      overflow="hidden"
      mode="wait"
      initial={{ x: "100%", opacity: 0 }}
      animate={{
        x: 0,
        opacity: 1,
        transition: { type: "spring", stiffness: 50 },
      }}
      exit={{
        x: "-100%",
        opacity: 0,
      }}
      as={motion.div}
    >
      {children}
    </Box>
  );
};

export default SlideTransition;
