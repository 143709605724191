import React from "react";
import "./i18n";
import "assets/css/App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/noto-sans-hebrew";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Switch, HashRouter,
} from "react-router-dom";
import AuthLayout from "./layouts/auth";
import RTLLayout from "./layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { AuthProvider } from "./auth-context/auth.context";
import { ProtectedRoute } from "./layouts/protected.route.js";
import { createRoot } from "react-dom/client";
import ReactSession from "./utils/ReactSession";
import { AnimatePresence } from "framer-motion";
import {AuthProtectedRoute} from "./layouts/AuthProtectedRoute";

//analytics.setCurrentScreen(window.location.pathname) // sets `screen_name` parameter
//analytics.logEvent('screen_view') // log event with `screen_name` parameter attached

ReactSession.setStoreType("localStorage");

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const App = () => {
  const location = useLocation();
  return (
    <AuthProvider>
      <AnimatePresence exitBeforeEnter mode="wait" initial={false}>
        <ChakraProvider theme={theme}>
          <Switch key={location.pathname} location={location}>
            <AuthProtectedRoute path={`/auth`} component={AuthLayout} />
            <ProtectedRoute path={`/`} component={RTLLayout} />
          </Switch>
        </ChakraProvider>
      </AnimatePresence>
    </AuthProvider>
  );
};
root.render(
  <HashRouter>
    <App />
  </HashRouter>
);
