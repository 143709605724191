// Create Fade Transition using Framer motion chakra ui

import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

const FadeTransition = ({ children }) => {
  return (
    <Box
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transitionDuration={"0.2s"}
      as={motion.div}
    >
      {children}
    </Box>
  );
};

export default FadeTransition;
