// Chakra imports
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  useColorModeValue,
  Image,
  border, useColorMode,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import WithdrawalsHistoryTable from "views/admin/default/components/WithdrawalsHistoryTable";
import WithdrawalRequest from "views/admin/default/components/WithdrawalRequest";
import WhatsAppIcon from "assets/img/icons/whatsapp.png";
import { Element } from "react-scroll";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import TermsConditions from "./components/TermsConditions";
import AuthApi from "api/auth";
import { useAuth } from "../../../auth-context/auth.context";
import EarningCard from "../../../components/card/EarningCard";
import TransfersBalanceCard from "../../../components/card/TransfersBalanceCard";
import TransfersApi from "../../../api/transfers";
import { TransfersResponse } from "../../../models/TransfersResponse";
import TimeAttendanceRepo from "../../../models/TimeAttendanceRepo";
import ReactSession from "../../../utils/ReactSession";
import { handleLogout } from "../../../utils/Logout";
import PageTransition from "components/pageTransition/PageTransition";
import FadeTransition from "../../../components/pageTransition/FadeTransiton";
import FasterFadeTransition from "../../../components/pageTransition/FasterFadeTransition";
import {defaultAnalytics, LogEvent} from "../../../analytics/analytics";

export default function Main() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { setUser } = useAuth();
  let { user } = useAuth();
  const [transfersDetails, setTransfersDetails] = useState({});
  const { colorMode, toggleColorMode } = useColorMode()


  const handleTermsConditionsDecline = async () => {
    // await AuthApi.Logout(user);
    localStorage.clear();
    await setUser(null);
    // localStorage.removeItem("user");
    // localStorage.removeItem("confirmTC");
    return history.push("/auth/signin");
  };
  const history = useHistory();

  const Scroll = require("react-scroll");

  const scrollToTransferRequest = () => {
    //alert(JSON.stringify(myRef));
    // myRef.current.scrollIntoView()
    LogEvent("transfer_now_click")
    var Element = Scroll.Element;
    var scroller = Scroll.scroller;
    // Somewhere else, even another file
    scroller.scrollTo("TransferRequestContainer", {
      duration: 200,
      delay: 50,
      smooth: true,
      offset: -90, // Scrolls to element + 50 pixels down the page
    });
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getTransfersInfo = () => {
    TransfersApi.getInfo()
      .then((res) => {
        if (res.data) {
          const response = TransfersResponse.from(res.data);
          setTransfersDetails(response);
          ReactSession.set("ta", res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getInfo = () => {
    AuthApi.GetUserInfo(null)
      .then((res) => {
        if (res.data) {
          ReactSession.set("user_info", res.data);
        }

        getTransfersInfo();
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.statusCode === 401
        ) {
          handleLogout(setUser, history).then((res) => {});
        }
      });
  };

  useEffect(() => {
    getInfo();
    LogEvent("main_view")
    if (colorMode === 'dark') {
      toggleColorMode()
    }
  }, []);

  const { t, i18n } = useTranslation();
  return (
    <FasterFadeTransition>
      <Box pt={{ base: "50px", md: "150px", xl: "150px" }} dir={t("direction")}>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap="20px"
          mb="20px">
          <EarningCard details={transfersDetails} />

          <TransfersBalanceCard
            details={transfersDetails}
            callBack={scrollToTransferRequest}
          />

          <Element name="TransferRequestContainer">
            <WithdrawalRequest details={transfersDetails} callBack={getInfo} />
          </Element>
        </SimpleGrid>
        <WithdrawalsHistoryTable details={transfersDetails} />

        <Box py="45px">
          <Button
            w="100%"
            backgroundColor="#d7f7d1" /**#83e76f*/
            fontSize="22px"
            textColor={"#1b580f"}
            height={"60px"}
            border={"1px"}
            borderColor={"#3fcd22"}
            onClick={() => {
              LogEvent("main_customer_support_click")
              openInNewTab("https://wa.me/972559995677");
            }}>
            {t("main_support_text")}
            <Image paddingRight="0px" src={WhatsAppIcon} px="10px" h="30px" />
          </Button>
        </Box>
      </Box>
    </FasterFadeTransition>
  );
}