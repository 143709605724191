import axios from "./index";
import MockApi from "./mockApi";
import {API} from "./env";
class AuthApi {

  static Auth = (aPhoneNumber, employeeId) => {
      if(MockApi.IsActive) {
          return MockApi.Auth();
      }

      let data = JSON.stringify({
          "phoneNumber": aPhoneNumber,
          "authType": 1,
          "id": employeeId
      });

      let config = {
          method: 'post',
          headers: {
              'Content-Type': 'application/json'
          },
          data : data
      };
      return axios.post(`${API}/auth`, data, config);
  };

    static Login = (otpToken) => {
        if(MockApi.IsActive) {
            return MockApi.Login();
        }

        this.sessionToken = localStorage.getItem("tempSessionToken")

        const data = JSON.stringify({
            "otp": otpToken,
            "sessionToken": this.sessionToken
        });

        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        return axios.post(`${API}/auth/otp`, data, config);
    };

    static GetUserInfo = (accessToken) => {
        if(MockApi.IsActive) {
            return MockApi.GetUserInfo();
        }

        if(!accessToken || undefined || accessToken.length === 0){
            let userInfo = JSON.parse(localStorage.getItem("user"));
            accessToken = userInfo.token;
        }

        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axios.get(`${API}/user`, config);
    };
}

export default AuthApi;