import React from "react";

import { Box, Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdLogout,
  MdOutlineContactSupport,
  MdOutlineShoppingCart,
} from "react-icons/md";

import { BsCalendarWeek } from "react-icons/bs";

// Admin Imports
import MainDashboard from "views/admin/default/Main";
import Profile from "views/admin/profile";

// Auth Imports
// import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn/index.jsx";
import SignUp from "views/auth/signUp/index.jsx";
import Otp from "views/auth/otp";
import Attendance from "./views/admin/attendance";
import Welcome from "views/welcome/WelcomeHome";
import WelcomeInfo from "views/welcome/WelcomeInfo";
import TermsConditions from "./views/auth/tc/TermsConditions";

const routes = [
  {
    name: "sidebar_main",
    layout: "/rtl",
    path: "/rtl-default",
    icon: (
      <Icon
        as={MdHome}
        width="35px"
        height="35px"
        padding={"1px"}
        color="inherit"
      />
    ),
    component: MainDashboard,
  },
  {
    name: "sidebar_shifts",
    layout: "/rtl",
    path: "/attendance",
    icon: (
      <Icon
        as={BsCalendarWeek}
        width="35px"
        height="35px"
        padding={"5px"}
        color="inherit"
      />
    ),
    component: Attendance,
  },
  {
    name: "sidebar_profile",
    layout: "/rtl",
    path: "/profile",
    icon: (
      <Icon
        as={MdPerson}
        width="35px"
        height="35px"
        padding={"2px"}
        color="inherit"
      />
    ),
    component: Profile,
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdHome} width="16px" height="16px" color="inherit" />,
    component: SignIn,
    hide: true,
  },
  {
    name: "Otp",
    layout: "/auth",
    path: "/otp",
    icon: <Icon as={MdHome} width="16px" height="16px" color="inherit" />,
    component: Otp,
    hide: true,
  },
  {
    name: "Welcome",
    layout: "/auth",
    path: "/welcome",
    icon: <Icon as={MdHome} width="16px" height="16px" color="inherit" />,
    component: Welcome,
    hide: true,
  },
  {
    name: "Welcome Info 1",
    layout: "/auth",
    path: "/welcome-info",
    icon: <Icon as={MdHome} width="16px" height="16px" color="inherit" />,
    component: WelcomeInfo,
    hide: true,
  },
  {
    name: "Terms Conditions",
    layout: "/auth",
    path: "/tc",
    icon: <Icon as={MdHome} width="16px" height="16px" color="inherit" />,
    component: TermsConditions,
    hide: true,
  },
];

export const Logout = [
  {
    name: "sidebar_logout",
    layout: "/auth",
    path: "/sign-out",
    icon: <Icon as={MdLogout} width="16px" height="16px" color="inherit" />,
    component: SignIn,
  },
];
export default routes;
