import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../auth-context/auth.context";
import { NavigateTo, Page } from "../nav/navigator";
import ReactSession from "../utils/ReactSession";

export const AuthProtectedRoute = ({ ...rest }) => {
  let { user } = useAuth();
  // if user, navigate to main
  if (user) {

    const tcView = ReactSession.get("tc_view");
    const shouldWatchTc = (tcView === undefined || tcView == null);
    if(!shouldWatchTc) {
      NavigateTo(Page.Main);
    } else {

    }
  }
  // if not user, continue as usual ?

  return <Route {...rest} />;
};
