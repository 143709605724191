export const columnsDataDevelopment = [
  {
    Header: "header_date",
    Footer: 'Summary',
    accessor: "date",
    width: "50px",
  },
  {
    Header: "header_sum",
    Footer: 'TotalPay',
    accessor: "pay",
    width: "40px",
  },
  {
    Header: "header_total_hours",
    Footer: 'TotalHours',
    accessor: "workingHours",
    width: "100px",
  },
];

