import {
	Box,
	Button,
	Center,
	HStack,
	Stack,
	useColorMode,
} from '@chakra-ui/react'
import SlideTransition from 'components/pageTransition/SlideTransition'
import { AnimatePresence } from 'framer-motion'
import { Page } from 'nav/navigator'
import {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import WelcomeInfoSection from 'theme/components/WelcomeInfoSection'
import welcomeTopImage1 from '../../assets/welcome/welcome_page_1_top.png'
import welcomeTopImage2 from '../../assets/welcome/welcome_page_2_top.png'
import { useTranslation } from 'react-i18next'
import {getMobileOperatingSystem, Mobile} from "../../utils/DeviceUtils";
import {useAuth} from "../../auth-context/auth.context";
import {LogEvent} from "../../analytics/analytics";

const WelcomeInfo = () => {
	const [currentPageIndex, setCurrentPageIndex] = useState(0)
	const history = useHistory()
	const { colorMode, toggleColorMode } = useColorMode()
	const { t } = useTranslation()
	const { user } = useAuth();

	useEffect(() => {
		if (user) {
			history.push('/rtl-default')
		}
		LogEvent("welcome_info")
	}, [])

	const welcomeData = [
		{
			image: welcomeTopImage1,
			heading: (
				<>
					{t('welcome_wizard_info_title_1')}
					<br />
					{t('welcome_wizard_info_title_2')}
				</>
			),

			text: (
				<>
					{t('welcome_wizard_info_message_1')}
					<Box
						mr="2"
						as="span"
						color={colorMode === 'dark' ? 'white' : '#2e3136'}
						fontWeight="bold"
					>
						{t('welcome_wizard_info_message_1_bold')}
					</Box>
					<br />
					{t('welcome_wizard_info_message_1_2')}
					<Box
						as="span"
						mr="2"
						color={colorMode === 'dark' ? 'white' : '#2e3136'}
						fontWeight="bold"
					>
						{t('welcome_wizard_info_message_1_2_bold')}
					</Box>
					<br />
					{t('welcome_wizard_info_message_1_3')}
				</>
			),
		},
		{
			image: welcomeTopImage2,
			heading: (
				<>
					{t('welcome_wizard_info_2_title_1')}
					<br />
					{t('welcome_wizard_info_2_title_2')}
				</>
			),
			text: (
				<>
					{t('welcome_wizard_info_2_message_1')}
				</>
			),
		},
	]

	const welcomeLength = welcomeData.length
	const handleNextPage = () => {
		if (currentPageIndex === welcomeLength - 1) {
			history.push(Page.SignIn.value)
		} else {
			setCurrentPageIndex((prev) => prev + 1)
		}
	}
	const mobileOs = getMobileOperatingSystem();

	return (
		<Stack
			minH={mobileOs === Mobile.Android ? "calc(100vh - 200px)" : "calc(100vh - 240px)"}
			style={{ backgroundColor: 'transparent' }}
			justify={'space-between'}
		>
			<Box w="full" overflow="hidden">
				{welcomeData.map((item, index) => {
					if (index === 0)
						return (
							<SlideTransition key={index}>
								{currentPageIndex === 0 && (
									<WelcomeInfoSection
										currentPageIndex={currentPageIndex}
										handleNextPage={handleNextPage}
										item={welcomeData[0]}
									/>
								)}
							</SlideTransition>
						)

					return (
						<AnimatePresence key={index}>
							{currentPageIndex === 1 && (
								<SlideTransition>
									<WelcomeInfoSection
										currentPageIndex={currentPageIndex}
										handleNextPage={handleNextPage}
										item={item}
									/>
								</SlideTransition>
							)}
						</AnimatePresence>
					)
				})}
			</Box>
			<Center>
				<HStack spacing={4}>
					{new Array(2).fill(0).map((_, i) => (
						<Box
							key={i}
							w="full"
							borderRadius="full"
							boxSize="8px"
							bg={i === currentPageIndex ? 'newBrand.500' : 'gray.500'}
						/>
					))}
				</HStack>
			</Center>

			<Stack mt={'50px'} w="full">
				<Button onClick={handleNextPage} w="full" variant="newOultineBrand">
					{t('next_button')}
				</Button>
			</Stack>
		</Stack>
	)
}

export default WelcomeInfo
