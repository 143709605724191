import { useHistory } from "react-router-dom";

export function NavigateTo(page) {
  const history = useHistory();
  if (page.value) {
    history.push(page.value);
  }
  // do nothing
}

export const Page = {
  SignIn: { value: "/auth/sign-in" },
  Welcome: { value: "/auth/welcome" },
  WelcomeInfo: { value: "/auth/welcome-info" },
  OTP: { value: "/auth/otp" },
  Main: { value: "/rtl-default" },
  TC: { value: "/auth/tc" },
};
