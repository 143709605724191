
export const banks = [
    {
        "id": 12,
        "name": "פועלים"
    },
    {
        "id": 11,
        "name": "דיסקונט"
    },
    {
        "id": 10,
        "name": "לאומי"
    },
    {
        "id": 20,
        "name": "מזרחי טפחות"
    },
    {
        "id": 4,
        "name": "בנק יהב"
    },
    {
        "id": 31,
        "name": "הבינלאומי"
    },
    {
        "id": 9,
        "name": "בנק הדואר"
    },
    {
        "id": 13,
        "name": "אגוד"
    },
    {
        "id": 14,
        "name": "אוצר החייל"
    },
    {
        "id": 17,
        "name": "מרכנתיל דיסקונט"
    },
    {
        "id": 34,
        "name": "בנק ערבי ישראלי"
    },
    {
        "id": 46,
        "name": "בנק מסד"
    },
    {
        "id": 52,
        "name": "פועלי אגודת ישראל"
    },
    {
        "id": 54,
        "name": "בנק ירושלים"
    }
]

export const prettifyBankNameById = (bankId) => {
    switch (bankId) {
        case "4":
            return "בנק יהב"
        case "9":
            return "בנק הדואר"
        case "10":
            return "לאומי"
        case "11":
            return "דיסקונט"
        case "12":
            return "פועלים"
        case "13":
            return "אגוד"
        case "14":
            return "אוצר החייל"
        case "17":
            return "מרכנתיל דיסקונט"
        case "20":
            return "מזרחי טפחות"
        case "22":
            return "סיטיבנק"
        case "31":
            return "הבינלאומי"
        case "34":
            return "בנק ערבי ישראלי"
        case "46":
            return "מסד"
        case "52":
            return "פועלי אגודת ישראל"
        case "54":
            return "ירושלים"
        case "99":
            return "בנק ישראל"
        default:
            return bankId;
    }
}
