import ReactSession from "./ReactSession";

/**
 * Handling Logout
 * @param setUser const { setUser } = useAuth()
 * @param history const history = useHistory()
 * @returns {Promise<*>}
 */
export const handleLogout = (setUser, history) => {
    localStorage.clear();
    setUser(null);
    ReactSession.remove("ta")
    ReactSession.remove("user_info")
    ReactSession.remove("earning_tab_info")
    ReactSession.remove("transfers_available_info")
    ReactSession.remove("welcome_wizard_view")
    ReactSession.remove("tc_view")
    history.push("/auth/welcome")
};
